import axios from "axios";


const cPost = 'gKq_ka6/4J4-ZlL'
const cGet = 'P6H_3Pm/bt7-EO4'
const cPut = 'nlK_ilP/gOm-VdP'
const cDel = '4U5_DvG/8hm-Oa4'

export default {

  resendCode(user, headers){
    return axios.post(`/${cPost}/reenviar-codigo`, user, headers);
  },

  login(user, headers){
    return axios.post(`/${cPost}/login`, user, headers);
  },

  getInfoUser(data, headers){
    return axios.post(`/${cPost}/info_usr`, data, {headers});
  },



  getAllInfo(option, headers){
    return axios.get(`/${cGet}/${option}`, {headers});
  },

  getStatusImage(payload, headers){
    return axios.get(`/${cGet}/${payload.option}-img/${payload.image}`, {headers});
  },


  getInfoById(payload, headers){
    return axios.get(`/${cGet}/${payload.option}/${payload.id}`, {headers});
  },

  


  findByCategoryName(payload, headers){
    return axios.get(`/${cGet}/${payload.option}/${payload.name}`, {headers});
  },


  findByUrl(payload, headers){
    return axios.get(`/${cGet}/${payload.option}/${payload.url}`, {headers});
  },

 
  search(data, headers){
    return axios.get(`/${cGet}/buscar/${data.normalizedOpt}/${data.search}`, {headers});
  },

 
  //metodos de eliminar, editar y agregar
  deleteItem(payload,token){
    return axios.delete(`/${cDel}/${payload.option}/delete`, {
        data: {
          id:payload.id
        },
        headers: {
          Authorization: token 
        } 
      });
  },
  sincronizar(payload,headers){
    return axios.get(`/${cGet}/sincronizar/`, {headers});
  },
  importProducts(payload,headers){
    return axios.get(`/${cGet}/importProducts/`, {headers});
  },
  deleteItemCar(payload,token){
    return axios.delete(`/${cDel}/${payload.option}/delete`, {
        data:payload.item,
        headers: {
          Authorization: token 
        } 
      });
  },
  
  
  editItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.put(`/${cPut}/${payload.option}/update`, payload.item, {headers} );
  },
  
  enviarMailContacto(data, headers){
    return axios.post('j43-wdrDE4s_wffs2/contacto', data, headers);
  },

  editImage(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.put(`/${cPut}/${payload.option}/upload/image/${payload.id}`, payload.item, {headers} );
  },

  addItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post(`/${cPost}/${payload.option}/create`, payload.item, {headers});
  },

  addItem2(payload,token){
   /* */
   let  headers = {
      Authorization: token
    }
    return axios.post(`/${cPost}/${payload.option}/create`, payload.item, {headers});
  },


  //envios
  getConsignmentNoteCategories( headers){
    return axios.get(`/${cGet}/consignment_notes_categories/`, {headers});
  },

  getConsignmentNoteSubcategories(payload, headers){
    return axios.get(`/${cGet}/consignment_notes_subcategories/${payload.CATEGORY_ID}`, {headers});
  },

  getConsignmentNoteClasses(payload, headers){
    return axios.get(`/${cGet}/consignment_notes_classes/${payload.SUBCATEGORY_ID}`, {headers});
  },

  getConsignmentNotePackagings( headers){
    return axios.get(`/${cGet}/consignment_notes_packagings/`, {headers});
  },

  getCarriers(token){
    let  headers = {
      Authorization: token
    }

    return axios.get(`/${cGet}/carriers/`, {headers});
  },

  getQuotations(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post(`/${cPost}/quotations/`, payload, {headers});
  },




  

};