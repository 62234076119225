<template>
  <div id="app">
    <whatsApp/>
    <router-view />
  </div>
</template>
<script>
import whatsApp from "./components/fixedItems/whatsApp";
import { mapActions } from "vuex";
export default {
  name: "App",
  created(){
    let _ctk = localStorage.getItem('_ctk')
   
    if(!_ctk){
      let payload={
        option:'set_cart',
        item:{id_cliente:null}
      }
      this.setCart(payload)
    }


  },
  components:{
    whatsApp,
  },
  methods:{
       ...mapActions("carrito", ["setCart"]),

  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Poppins:wght@300;400;500;600;700&display=swap");
:root {
  --color-1: #f8a041;
  --color-2: #00b2a2;
  --color-3: #48deff;
  --color-4: #8d8d8d;
  --color-5: #ffffff;
  --color-6: rgba(255, 255, 255, 0.3);
  --color-7: #f24e4e;
  --color-8: rgba(0, 178, 162, 0.6);
  --color-9: #f54c49;
  --color-10: #3383b1;
  --color-11: #215675;

  --oro: #fe9128;
  --turquesa: #3ebdb1;

  --Poppins: "Poppins", sans-serif;
  --Lato: "Lato", sans-serif;
}

@font-face {
  font-family: "Helvetica World Bold";
  src: url("./assets/fonts/HelveticaWorld-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Helvetica World Regular";
  src: url("./assets/fonts/HelveticaWorld-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Intro Rust";
  src: url("./assets/fonts/introrust-base.otf");
  font-display: swap;
}
#app {
  margin: 0vw;
  height: 100%;
  font-family: var(--Poppins);
}

@media (max-width: 967px) {
  #app {
    margin: 0vw;
    height: 100vh;
    font-family: var(--Poppins);
  }
}

html{
  scroll-behavior: smooth;
}

body {
  margin: 0px;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}

.coblue{
  color: #219176!important;
}
.bblue{
  border: 0.05vw solid #219176!important;
  color: #000000!important;
  font-weight: 700!important;
}

 span.alert{
        color: var(--color-1);
 }
  span.asignado{
        color: var(--color-2);
 }

/** bloques admin */
#bloques_sec {
  margin-bottom: 2.5520833333333335VW;
}
#bloques_sec a{
  margin: 0vw;
width: 10.11VW;
  text-align: center;
  background: #DEDEDE;
  padding: 0.20833333333333334VW 0vw ; 
  color: white;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 0.7291666666666666VW;
  line-height:1.09375VW;
  text-align: center;
  text-transform: uppercase;
  transition: all 300ms;
  cursor: pointer;
  text-decoration: none;
}
#bloques_sec a.act-bloq{
  background: #00B2A2;
}
#bloques_sec a:hover{
  background: #00B2A2;
}
img.totop{
      margin-bottom: 0.3vw;
    margin-left: 0.3vw;
}
.mbtom{
  margin-bottom: 5vw;
}


/** MODALS */
.modal_view_checkout  {
  z-index: 900;
  background-color: rgb(164 164 164 / 32%);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0vw;
  right: 0vw;
  
}
p.btnRegresar img, p.btnCancelar img{
     width: 67%;
    height: 0.7vw;
    margin: 0vw;
}

img.close_modal{
  margin-left: 95%;
  width: 1vw;
  height: 1vw;
  margin-bottom: 1vw;
  cursor: pointer;
}

.dv-sec{
  height: .5vw;
  margin-top: 1vw;
  margin-bottom: 1vw;
   border-bottom: .13vw solid #B1B1B1;
  width: 90.7%;
}


#cnt-mdl-checkout{
     background: var(--color-5);
    height: auto;
    padding: 1vw;
    width: 23%;
    margin-left: 36%;
    margin-top: 3%;
}
.pymnt-cntnt{
  padding-bottom: 2vw;
}
.ve-body{
    border: 1px solid gainsboro;
    margin-bottom: 2vw;
    padding:  1vw 2vw;
        margin-top: 48%;
}
.ve-body p{
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: .7vw;
  text-align: center;

  color: #828282;

}
.ve-cnt a{
    width: 100%;
  height: 3.906vw;
  display: flex;
 
  align-items: center;
  justify-content: center;
  background-color: #fe9128;
  cursor: pointer;
    font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 2.031vw;
  color: #ffffff;
}
/** FIN MODALS */

.dflx {
  display: flex;
}
.sp{
  justify-content: space-between;
}
.dpl {
  display: none;
}
.pointer{
  cursor: pointer;
}
hr.hrhd {
  width: 70.72916666666667vw;
  margin-left: 0px;
  /*border-bottom: 1px solid #b1b1b1;*/
  margin-top: 1.6145833333333335vw;
  margin-bottom: 1vw;
}
hr.hrdr{
      width: 70.72916666666667VW;
    margin-left: 0px;
    /*border-bottom: 1px solid #B1B1B1;*/
    margin-top: 3VW;
    margin-bottom: 1vW;
}
.mtp{
  margin-top: 2vw ;
}
p.hrd_tbli{
  background: var(--color-1);
  color: var(--color-5);
  text-align: center;
  padding: .5vw 0vw ;
  margin: 0vw;

}
p.subtitle{
  color: var(--color-2);
  margin: 0vw;
  font-size: 1.3020833333333335VW;
  margin-top: 2vw;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1vw;
}
.user-img {
  width: 5.156vw;
  height: 5.156vw;
  border-radius: 5.833vw;
}
/** MENSAJES DE EXITO/ERROR */
.status_messages {
  margin-top: 1vw;
}
.status_messages p {
  color: white !important;
}
p.snu{
   color: var(--color-4);
   text-align: center;
    font-family: var(--Poppins);
  font-size: 0.7333333333333334vw;
  font-style: normal;
}
.msg p {
  color: var(--color-5);
  width: 100%;
  padding: 0.5vw;
  font-family: var(--Poppins);
  font-size: 0.7333333333333334vw;
  font-style: normal;
}
.msg_success {
  background: var(--color-2);
  width: 20vw;
}

.msg_error p {
  background: var(--color-7);
  width: 20vw;
}

/** FIN MENSAJES DE EXITO/ERROR */

/** NAVIGATE BUTTONS */
#navigate-buttons {
  margin-left: 1.3vw;
  margin-top: 0.1vw;
}

#navigate-buttons button {
  border: 0px;
  background: var(--color-5);
  width: 1.5vw;
  height: 1.5vw;
  cursor: pointer;
  transition: all 300ms;
  color: var(--color-5);
  font-size: 1vw;
  margin-top: 0.7vw;
}

/*#navigate-buttons button:hover{
       
        background: var(--color-2);
       
        
    }*/
p.num-pages {
  color: #797979;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  padding-top: 0.2vw;
  font-size: 0.7vw;
}

/** FIN NAVIGATE BUTTONS */

/** MODAL delete*/

.modal_view {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0vw;
  right: 0vw;
}
#modal_delete {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}
#modal_delete .body {
  background: var(--color-1);
  width: 16.354166666666668vw;
  height: 6.354166666666666vw;
  border-radius: 1.5vw;
  justify-content: center;
}

#modal_delete h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  text-align: center;
  font-weight: 400;
  margin-top: 0vw;
}
#modal_delete h3 span {
  font-weight: 700;
}
#modal_delete img {
  margin-top: 1.0416666666666665vw;
  margin-left: 7.239583333333334vw;
  width: 1.9270833333333335vw;
  height: 1.8229166666666667vw;
}

#modal_cancelar {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}
#modal_cancelar .body {
  background: var(--color-1);
  width: 16.354166666666668vw;
  height: auto;
  border-radius: 1.5vw;
  display: grid;
  align-items: center;
  justify-content: center;
  padding-bottom: 2vw;
}

#modal_cancelar h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  text-align: center;
  font-weight: 400;
  margin-top: 0vw;
}
#modal_cancelar textarea {
  font-size: 0.7vw;
  color: var(--color-4);
  font-weight: 400;
  margin-top: 0vw;
  height: 5vw !important;
  width: 12vw !important;
  border: none;
  border-radius: .5vw;
}
#modal_cancelar h3 span {
  font-weight: 700;
}
#modal_cancelar img {
  margin-top: 1.0416666666666665vw;
  margin-left: 42%;
  width: 1.9270833333333335vw;
  height: 1.8229166666666667vw;
}

.modal_del_btns {
  justify-content: center;
}

.modal_del_btns p,  .modal_del_btns button{
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
  background: none;
  border: none;
}

#AddCat input#exclusivo{
  width: 4.5vw;
}
/** fin modal delete */

/** modal add */
#modal_add {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}
#modal_add .body {
  background: var(--color-1);
  width: 16.354166666666668vw;
  height: 2.3958333333333335vw;
  border-radius: 1.5vw;
}

#modal_add h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  padding-left: 1.5vw;
  font-weight: 400;
  padding-top: 0.6770833333333334vw;
  margin-top: 0vw;
  width: 10vw;
}
#modal_add img {
  padding-top: 0.54vw;
  padding-left: 3vw;
  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
}

.modal_add_btns {
  justify-content: center;
}

.modal_add_btns p {
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}
/** fin  modal add */

/** modal edit */
#modal_edit {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}
#modal_edit .body {
  background: var(--color-1);
  width: 16.354166666666668vw;
  height: 2.3958333333333335vw;
  border-radius: 1.5vw;
}

#modal_edit h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  padding-left: 1.5vw;
  font-weight: 400;
  width: 13vw;
  padding-top: 0.6770833333333334vw;
  margin-top: 0vw;
}
#modal_edit img {
  padding-top: 0.54vw;

  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
}

.img-item img {
  max-width: 15vw;
}

.modal_edit_btns {
  justify-content: center;
}

.modal_edit_btns p {
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}
/** fin  modal add */

/** modal logout */

#modal_logout {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}
#modal_logout .body {
  background: var(--color-2);
  width: 16.354166666666668vw;
  height: 6.354166666666666vw;
  border-radius: 1.5vw;
  justify-content: center;
}

#modal_logout h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  text-align: center;
  font-weight: 400;
  margin-top: 0.5vw;
}
#modal_logout h3 span {
  font-weight: 700;
}
#modal_logout img {
  margin-top: 1.0416666666666665vw;
  margin-left: 7.239583333333334vw;
  width: 1.875vw;
  height: 1.875vw;
}

.modal_log_btns {
  justify-content: center;
}

.modal_log_btns p {
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}

p.noitems {
  color: var(--color-4);
  font-size: 0.625vw;
  font-weight: 500;
  width: 4.947916666666666vw;
  margin-right: 1.5625vw;
}
p.noitems span {
  font-weight: 700;
}

p.msg_text {
  color: var(--color-4);
  font-size: 0.625vw;
  font-weight: 500;
  margin-right: 1.5625vw;
  margin-top: 1.5vw;
}
p.msg_text2 {
  color: var(--color-2);
  font-size: 0.8vw;
  font-weight: 500;
  margin-right: 1.5625vw;
  margin-top: 0vw;
  margin-bottom: 0vw;
  margin-left: 1vw;
}
p.msg_text2 span{
  color: var(--color-1);

  font-weight: 800;
 
}

#select-items {
  width: 8.90625vw;
  border: 0px;
  background: var(--color-4);
  border-radius: 0.5vw;
  color: var(--color-5);
  font-size: 0.625vw;
  font-weight: 500;
  border: none;
  margin-right: 1.9270833333333335vw;
}
.activeradius {
  border-radius: 0.5vw 0.5vw 0vw 0vw !important;
}

#select-items option {
  background: white;
  color: #8d8d8d;
}
.buscador {
  margin-top: 0.2vw;
  height: 1.5625vw;
  border: 0.052083vw solid var(--color-4);
  border-radius: 0.5vw;
  padding-left: 0.5vw;
}
.buscador input {
  width: 15.312500000000002vw;
  border: none;
  height: 1.5625vw;
  color: #8d8d8d;
}
.icon_buscar img {
  width: 0.8854166666666666vw;
  height: 0.8854166666666666vw;
  padding-top: 0.3vw;
  margin-right: 0.5vw;
}

.btn_añadir {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  /*width: 15.416666666666668vw;*/
  width: fit-content;
  height: 2.5vw;
  text-align: left;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
}
.buttons_section{
  display: flex;
}
.bg_blue{
  margin-left: 10px;
  background: #2196f3 !important;
}
.bg_gray{
  margin-left: 10px;
  background: #607d8b !important;
}
.bg_red{
  background: #ff0000 !important;
}
.sync {
    width: 10px;
    height: 10px;
    background: #0fb833;
    border-radius: 10px;
    position: absolute;
    margin-left: -10px;
}
.no_sync {
    width: 10px;
    height: 10px;
    background: #ff0000;
    border-radius: 10px;
    position: absolute;
    margin-left: -10px;
}
.loading {
  background: transparent url('https://miro.medium.com/max/882/1*9EBHIOzhE1XfMYoKz1JcsQ.gif') center no-repeat;
  height: 200px;
  width: 50%;
  margin-left: 25%;
  background-size: contain;
}
.check-box-it{
  display: none;
}
.subtitle2 {
    font-size: 1.62vw;
    line-height: 1.3vw;
    font-weight: 500;
    color: #00b2a2;
    margin-top: 4vw;
    text-transform: uppercase;
}
.btn_añadir img {
  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
  margin-left: 5.5vw;
  margin-right: 0.8vw;
  padding-top: 0.6vw;
}
.btn_añadir p {
  font-weight: 500;
  font-size: 0.7291666666666666vw;
  padding-bottom: 0.2vw;
  padding-left: 1.5vw;
  text-transform: capitalize;
}

/** estilos scroll bar */
.tbl_item::-webkit-scrollbar {
  -webkit-appearance: none;
}

.tbl_item::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
}

.tbl_item::-webkit-scrollbar-button:increment,
.tbl_item::-webkit-scrollbar-button {
  display: none;
}

.tbl_item::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

.tbl_item::-webkit-scrollbar-thumb {
  background-color: #797979;
  /*border-radius: 1.0416666666666665VW;*/
  border: 0.10416666666666667vw solid #f1f2f3;
}

.tbl_item::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

.tbl1 {
  width: 20vw;

  margin-right: 1vw;
}

p.etiqueta {
  color: var(--color-2);

  font-size: 0.6vw;
}

.hdreti {
  background: var(--color-5);
  padding: 0.5vw 1vw;
  color: var(--color-1);
  font-weight: 600;
  font-size: 0.8vw;
}

.bodytbl {
  height: 10vw;
  overflow: auto;
  padding: 1vw;
  background: var(--color-5);
}

.boxform {
  display: grid;
  margin-right: 4.375vw;
  margin-bottom: 0.8854166666666666vw;
}

p.btnRegresar {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 6vw;
  padding: 0.7vw 0.7vw;
  text-align: center;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
  font-weight: 500;
  font-size: 0.7291666666666666vw;
  margin-right: 2vw;
}
p.btnCancelar {
  cursor: pointer;
  border: none;
  background: var(--color-7);
  color: var(--color-5);
  width: 6vw;
  padding: 0.7vw 0.7vw;
  text-align: center;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
  font-weight: 500;
  font-size: 0.7291666666666666vw;
  margin-right: 2vw;
}

button.alta, p.alta {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 6vw;
  height: 2.5vw;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
  font-weight: 600;
  font-size: 0.7291666666666666vw;
}
p.alta {
    display: flex;
    justify-content: center;
    align-items: center;
}
.pointer{
  cursor: pointer;
}
.sbtw{
  justify-content: space-between;
}
.btn_añadir2 {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 11.1vw;
  height: 2.5vw;
  text-align: left;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
}
.btn_añadir2 img {
  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
  margin-left: 1.5vw;
  padding-top: 0.6vw;
}
.btn_añadir2 p {
  font-weight: 500;
  font-size: 0.7291666666666666vw;
  padding-bottom: 0.2vw;
  padding-left: 1.5vw;
}

.btn_añadir2 p span {
  font-weight: 700;
}
.tbl_item p{
  font-size: .8vw;
}

#uploadimg button {
  border: none !important;
  background: var(--color-5);
}

input[type="file"] {
  display: none;
}

.filelabel {
  padding: 0.8vw 0vw;
  width: 14.716666666666668vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4) !important;
  font-size: 0.7291666666666666vw;
  font-weight: 500 !important;
  padding-left: 1.09375vw;
  cursor: pointer;
}

.filelabelname {
  padding: 0.8vw 1vw;
  border-left: none;
  width: auto;
  cursor: pointer;

  color: var(--color-4) !important;
  font-size: 0.7291666666666666vw;
  font-weight: 500 !important;
  padding-left: 1.09375vw;
}

/*select */

.select-container {
  margin-right: 1.9270833333333335vw;
}
#select-items {
  width: 8.90625vw;
  height: 1.563vw;
  margin-top: 0.2vw;
  border: 0px;
  background-color: var(--color-4);
  border-radius: 0.5vw;
  color: var(--color-5);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  border: none;
  padding-left: 0.938vw;
  display: flex;
  align-items: center;
  background-image: url("./assets/flecha.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 8.6vw;
}
input#select-ch {
  display: none;
}
.options-cont {
  display: none;
}
#select-ch:checked ~ .options-cont {
  display: flex;
  position: absolute;
  padding: 0.573vw 1.25vw;
  flex-direction: column;
  background-color: white;
  width: 7.25vw !important;
}
.op-act {
  display: flex;
  position: relative;
  padding: 0.573vw 1.22vw;
  flex-direction: column;
  background-color: white;
  width: 13.5vw;
  border-top: transparent !important;
  border: 0.052083vw solid var(--color-2);
}
#select-items.rounded {
  border-radius: 0.5vw 0.5vw 0 0;
}
.in1.rounded {
  border-radius: 0vw 1.5vw 0vw 0vw !important;
  border-bottom: transparent !important;
}
.option-btn {
  cursor: pointer;
  font-size: 0.625vw;
  line-height: 1.25vw;
}
.option-btn:hover {
  background: gainsboro;
}

.activeradius {
  border-radius: 0.5vw 0.5vw 0vw 0vw !important;
}

#select-items option {
  background: white;
  color: #8d8d8d;
}

/*select */
.buscador-items {
  margin-left: 0;
  margin-top: 0.2vw;
  height: 1.5625vw;
  border: 0.052083vw solid var(--color-4);
  border-radius: 0.5vw;
  padding-left: 0.5vw;
}
.searching-it {
  border: transparent !important;
  height: 1.5vw !important;
}
#tipos {
  width: 15.46667vw !important;
}

.rows_tbl{
  width: 90%;
}
p.msg_skydropx{
  color: var(--color-1);
  font-size: 1vw;
  margin: 0vw;
  margin-top: 1vw;
  font-weight: 600;
}
p.msg_skydropx2{
  color: var(--color-4);
  font-size: .7vw;
   margin: 0vw;
   margin-bottom: 1vw;
}
label span{
  color: var(--color-1);
  font-weight: 400;
  font-size: .7vw;
}
p.message-error{
  font-weight: 400;
    font-size: 0.781vw;
    line-height: 129.5%;
    text-align: center;
    color: white;
    background: var(--color-7);
    padding: 0.6vw 1vw;
    width: 58%;
}
.rows_tbl > .tblitems{
  width: 30%;
  margin-right: 1vw;
}
.rows_tbl > .tblitems > .bodytbl-items{
  height: 12vw;
}

p.addcp{
  font-weight: 400;
font-size: 1vw;
margin-top: .7vw;
margin-bottom: 0vw;

}
/***tabla */
.content-tbl-items {
  margin: 0.4vw 0;
}
.bodytbl-items {
     border: 0.052083vw solid var(--color-2);
    padding: 0.5vw;
    height: 5vw;
    font-size: .8vw;
    overflow: auto;
    background: var(--color-2);
    color: var(--color-5);
}
img.img_item{
  height: 10vw;
}
p.itemp:hover {
  background: var(--color-3);
  color: var(--color-5);
}
p.cp {
  cursor: pointer;
}
textarea#name {
  width: 57.6vw;
  height: 6vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  padding-left: 1.09375vw;
}
.url-input {
  width: 37vw !important;
}

.item_gallery .row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.item_gallery img {
  max-width: 16vw;
  height: 11vw;
  border-radius: 1vw;
  margin: 1.22vw 0;
}
.grid-cont {
  display: flex;
  flex-wrap: wrap;
  width: 68vw;
}
.item_gallery p.eliminar-gallery {
  margin: 0;
  font-size: 1.45vw;
  color: #d6d5d5;
  background: white;
  width: 2vw;
  height: 2vw;
  border-radius: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  transform: scale(1);
}
.item_gallery p.eliminar-gallery:hover {
  transform: scale(1.1);
}
.item_gallery .row {
  background: #d6d5d5;
  margin: 2vw 0;
  height: fit-content;
  border-radius: 2vw;
  width: 25vw;
}
.item_gallery:nth-child(even) .row {
  margin: 2vw 10vw 2vw 0 !important;
}
.btnsgrp {
    border: 0.05vw solid var(--color-2);
    width: fit-content;
    border-radius: 21vw;
    overflow: hidden;
}
.btnopciones {
    padding: 0.4vw 1.2vw;
    margin: 0;
    font-size: .6vw;
    cursor: pointer;
}
.btnoact{
    background: var(--color-2);
    color: white;
}
@media (max-width: 768px) {
  .sec-1 .VueCarousel-pagination {
    top: 137.135vw;
  }
  .blog-items-cont.mo .VueCarousel {
    width: 90vw;
  }
  .blog-items-cont.mo .VueCarousel-pagination {
    top: 142.135vw;
  }

  .modal_view_checkout {
  z-index: 900;
  background-color: rgb(164 164 164 / 32%);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0vw;
  right: 0vw;
  
}

img.close_modal{
  margin-left: 95%;
  width: 1vw;
  height: 1vw;
  margin-bottom: 1vw;
  cursor: pointer;
}

img.close_modal {
    margin-left: 91%;
    width: 6vw;
    height: 5vw;
    margin-bottom: 4vw;
}

.ve-body p {

    font-size: 3.7vw;

}
.ve-cnt a {
   
    height: 15.906vw;
   
    font-size: 3.833vw;
}
#cnt-mdl-checkout{

    height: 70%;
    padding: 4%;
    width: 82%;
    margin-left: 4.8%;
    margin-top: 30%;
}

.msg_error p {
   font-size: 3vw;
   text-align: center;
    width: 99%;
}
}
</style>
