import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import address from './modules/address'
import main from './modules/main'
import usuarios from './modules/usuarios'
import category  from './modules/category'
import product  from './modules/product'
import product_cat  from './modules/product_cat'
import product_val  from './modules/product_val'
import brand  from './modules/brand'
import banner  from './modules/banner'
import shopcar  from './modules/shopcar'
import stock  from './modules/stock'
import carrito from './modules/carrito'
import pasarela from './modules/pasarela'
import payment from './modules/payment'
import suscripciones from './modules/suscripciones'
import wishlist from './modules/wishlist'
import atributo from './modules/atributo'
import mirror from './modules/mirror'
import blog from './modules/blog'
import tag from './modules/tag'
import colaboradores from './modules/colaboradores';
import blog_category from './modules/blog_category'
import shipment from './modules/shipment'
import blog_comment from './modules/blog_comment'
import global_params from './modules/global_params'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    address:address,
    main: main,
    category: category,
    usuarios: usuarios,
    product: product,
    product_cat: product_cat,
    brand: brand,
    banner: banner,
    shopcar: shopcar,
    stock: stock,
    carrito: carrito,
    pasarela: pasarela,
    payment: payment,
    suscripciones: suscripciones,
    wishlist: wishlist,
    atributo: atributo,
    mirror:mirror,
    blog:blog,
    blog_comment:blog_comment,
    tag:tag,
    blog_category:blog_category,
    colaboradores: colaboradores,
    product_val:product_val,
    shipment:shipment,
    global_params: global_params
  }
})
