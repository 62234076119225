<template>
  <div>
    <div class="whatsapp" :class="{'upwapp':active1}">
      <a v-if="this.$route.path != '/administrador'"
        href="https://api.whatsapp.com/send?phone=526623380984&text=%C2%A1Hola!%20Deseo%20m%C3%A1s%20informaci%C3%B3n%20de%20la%20tienda."
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src="../../assets/whatsapp.svg" alt="whatsapp" class="icon" />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active1:false,
    }
  },
  computed: {
    routename(){
      let route = this.$route.path.split('/')
      let first = route[1]
      if(first=='mi-cuenta'){
        this.active1=true;
      }
      return this.$route.path;
    }
  },
};
</script>
<style>
.whatsapp {
  position: fixed;
  z-index: 2000;
  width: 3.125vw;
  height: 3.125vw;
  bottom: 1.563vw;
  right: 1.563vw;
  transition: .5s;
}
img.icon {
  width: 100%;
}
@media (max-width: 768px) {
.upwapp{
    bottom: 27.246vw!important;
}
    .whatsapp{
        width: 14.493vw;
        height: 14.493vw;
        bottom: 7.246vw;
        right: 7.246vw;
    }
}
</style>